<!--
 * @Description: 备忘录
 * @Author: 琢磨先生
 * @Date: 2024-01-22 15:35:07
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-07-01 23:03:52
-->

<template>
  <el-card class="box query">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="关键字">
        <el-input
          v-model="query.q"
          placeholder="内容关键字"
          style="width: 300px"
        ></el-input>
      </el-form-item>

      <el-form-item label="房间">
        <el-cascader
          :options="huxing_list"
          v-model="query.storeIds"
          clearable
          style="width: 300px"
          filterable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="类型">
        <el-select v-model="query.type_id" clearable style="width: 150px">
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="query.status"
          placeholder
          clearable
          style="width: 150px"
        >
          <el-option
            :label="item.text"
            :value="item.value"
            v-for="item in enums.note_status"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker
          v-model="query.dates"
          type="daterange"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="创建人">
        <el-input
          v-model="form.emp_name"
          placeholder="姓名"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="已删">
        <el-select v-model="query.is_del" placeholder="" clearable style="width: 100px;">
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div>
      <el-button
        type="primary"
        size="small"
        @click="onEdit()"
        round
        icon="plus"
        v-if="is_can_edit"
        >新增</el-button
      >
    </div>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table
      :data="tableData.data"
      @sort-change="onSort"
      border
      row-key="id"
      @selection-change="tableSelectionChange"
      :cell-class-name="cellClassHandler"
    >
      <el-table-column
        label
        type="selection"
        reserve-selection
      ></el-table-column>
      <el-table-column prop="id" label="编号ID" width="80"></el-table-column>
      <el-table-column
        prop="typeItem.name"
        label="类型"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="store.house_no"
        label="关联房号"
        width="200"
      ></el-table-column>
      <el-table-column label="状态" width="100">
        <template #default="scope">
          <el-link v-if="scope.row.status == 0" :underline="false">{{
            scope.row.status_text
          }}</el-link>
          <el-link
            type="warning"
            v-else-if="scope.row.status == 10"
            :underline="false"
            >{{ scope.row.status_text }}</el-link
          >
          <el-link
            type="primary"
            v-else-if="scope.row.status == 20"
            :underline="false"
            >{{ scope.row.status_text }}</el-link
          >
          <el-link
            type="success"
            v-else-if="scope.row.status == 30"
            :underline="false"
            >{{ scope.row.status_text }}</el-link
          >
          <el-link v-else>{{ scope.row.status_text }}</el-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="content"
        label="内容"
        min-width="300"
      ></el-table-column>
      <el-table-column label="可见" prop="visible_text" width="100"></el-table-column>
      <el-table-column
        prop="emp.chs_name"
        label="创建人"
        width="100"
      ></el-table-column>
      <el-table-column label="已删除(时间)" width="180">
        <template #default="scope">
          {{ scope.row.is_deleted ? `是(${scope.row.deleted_at})` : "--" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="create_at"
        label="创建时间"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" fixed="right" width="160">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onDtl(scope.row)"
            icon="View"
            >详情</el-button
          >
          <el-button
            type="primary"
            link
            size="small"
            icon="edit"
            @click="onEdit(scope.row)"
            v-if="is_can_edit"
            >修改</el-button
          >
          <el-button
            type="danger"
            v-if="is_can_del"
            icon="delete"
            link
            :loading="scope.row.deleting"
            size="small"
            @click="onDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
      v-if="tableData.counts > 0"
    ></el-pagination>
  </el-card>

  <note-edit
    :item="current_edit"
    @success="loadData"
    @closed="current_edit = null"
  ></note-edit>
  <note-dtl
    :item="current_dtl"
    @close="current_dtl = null"
    @change="loadData"
  ></note-dtl>
</template>

<script>
import huxing_api from "@/http/huxing_api";
import common_api from "@/http/common_api";
import note_api from "@/http/note_api";
import NoteDtl from "./note_dtl.vue";
import NoteEdit from "./note_edit.vue";
export default {
  components: {
    NoteDtl,
    NoteEdit,
  },
  data() {
    return {
      loading: false,
      title: "",
      huxing_list: [],
      enums: {},
      // payment_terms: [],
      //项目类型列表
      typeList: [],
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      //选中的数据
      selection_list: [],
      form: {},

      rules: {
        type_id: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
      current_edit: null,
      //详情对象
      current_dtl: null,
      is_can_edit: false,
      is_can_del: false,
    };
  },
  created() {
    this.is_can_edit = this.$power("seller/v1/note/edit");
    this.is_can_del = this.$power("seller/v1/note/del");
    note_api.get_usable_types().then((res) => {
      if (res.code == 0) {
        this.typeList = res.data;
      }
    });
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });
    huxing_api.get_cascader().then((res) => {
      if (res.code == 0) {
        this.huxing_list = [];
        res.data.forEach((x) => {
          var model = {
            value: x.id,
            label: x.name,
            children: [],
          };
          x.store_list.forEach((item) => {
            model.children.push({
              value: item.id,
              label: item.house_no,
            });
          });
          this.huxing_list.push(model);
        });
      }
    });
    this.loadData();
  },
  methods: {
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 选中更改
     */
    tableSelectionChange(nodes) {
      this.selection_list = nodes;
    },

    /**
     * 加载统计数据
     */
    loadData() {
      this.loading = true;
      this.$http
        .post("seller/v1/note", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },

    /**
     * 新增、修改
     * @param {*} item
     */
    onEdit(item) {
      this.current_edit = Object.assign({}, item);
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      // this.$confirm("您确定要删除此数据？").then(() => {
      //   item.deleting = true;
      //   this.$http.get("seller/v1/note/del?id=" + item.id).then((res) => {
      //     if (res.code == 0) {
      //       this.$message({ type: "success", message: res.msg });
      //       this.loadData();
      //     }
      //   });
      // });
      this.$prompt(`您确定要删除编号[${item.id}]数据？请填写备注`, "温馨提示", {
        inputType: "textarea",
        draggable: true,
        inputErrorMessage: "",
        inputValidator: function (value) {
          if (value) {
            return true;
          }
          return "请输入备注内容";
        },
      }).then(({ value }) => {
        if (value) {
          this.$http
            .post("seller/v1/note/del", {
              id: item.id,
              remark: value,
            })
            .then((res) => {
              if (res.code == 0) {
                this.$message({ type: "success", message: res.msg });
                this.loadData();
              }
            });
        }
      });
    },

    /**
     * 查看详情
     */
    onDtl(item) {
      this.current_dtl = item;
    },

    /**
     * 已删除的数据样式
     * @param {*} e
     */
    cellClassHandler(e) {
      if (e.row.is_deleted) {
        return "table-cell-del";
      }
    },
  },
};
</script>

<style scoped></style>
